'use client';
import { useRouter } from 'next/navigation';
import React, { useCallback } from 'react';
import Link from 'next/link';

type PrefetchOnHoverLinkProps = Omit<React.ComponentPropsWithoutRef<typeof Link>, 'prefetch'>;

export const PrefetchOnHoverLink: React.FC<PrefetchOnHoverLinkProps> = ({ href, children, ...props }) => {
  const router = useRouter();
  const handleMouseEnter = useCallback(() => {
    router.prefetch(href as string);
  }, [href, router]);

  return (
    <Link href={href} prefetch={false} onMouseEnter={handleMouseEnter} {...props}>
      {children}
    </Link>
  );
};
